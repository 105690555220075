// this is required for invitations/edit as there were issues with js not bein run in the case of render for form error from controller.
window.processMultiStepForm = () => {
  preventDefaultButton();

  processStep();
  processFormSubmit();

  function preventDefaultButton() {
    $('.next-step-btn, .previous-step-btn').on('click', function (e) {
      e.preventDefault();
    })
  }

  function areAllRequiredFieldsFilled(fields) {
    var isValid = true;
    $(fields).each(function () {
      var value = $(this).val()
      if (value.length === 0)
        isValid = false;
    });
    return isValid;
  }

  function processStep() {
    $('.next-step-btn, .previous-step-btn').on('click', function (e) {
      let currentSection = $(`#${$(e.target).data('current')}`);
      if (currentSection.length !== 0) {
        const inputs = currentSection[0].querySelectorAll('input');
        let isValid = true;

        inputs.forEach(input => {
          if (!input.checkValidity()) {
            input.reportValidity();
            isValid = false;
          }
        });

        let allRequiredFields = currentSection.find('.requiredField');
        if (!(areAllRequiredFieldsFilled(allRequiredFields))) {
          Swal.fire({
            icon: 'error',
            title: 'Cannot continue...',
            text: 'Please fill all the required fields!'
          })
          e.stopPropagation();
          e.preventDefault();
        } else {
          if(isValid) {
            var targetId = $(this).data("target")
            $(targetId).click();
          }else {
            e.stopPropagation();
            e.preventDefault();
          }
        }
      } else {
        var targetId = $(this).data("target")
        $(targetId).click();
      }
    });
  }

  function processFormSubmit() {
    $('.triggerValidation').on('click', function(e) {
      console.log('clicked trigger validation');
      let currentSection = $(`#${$(e.target).data('current')}`);
      if (currentSection.length !== 0) {
        let allRequiredFields = currentSection.find('.requiredField');
        if (!(areAllRequiredFieldsFilled(allRequiredFields))) {
          Swal.fire({
            icon: 'error',
            title: 'Cannot continue...',
            text: 'Please fill all the required fields!'
          })
          e.stopPropagation();
          e.preventDefault();
        }
      }
    });
  }

}

document.addEventListener('turbolinks:load', function () {
  if ($('.projects.new, .projects.hud_project, .projects.create, .projects.edit, .projects.update, .organizations.new, .organizations.create, .organizations.edit, .organizations.update, .billings.new, .billings.create, .billings.edit, .billings.update, .teams.edit, .projects.create_hud_project, .projects.edit_hud_project, .projects.update_hud_project, #newSessionModal').length === 0) {
    return false
  }

  // do not want to trigger this js if we are working on permit applications form
  // even though it is permit applications form, it is served under projects/edit so this js gets triggered
  if($('.permit_applications-form-section').length !== 0) {
    return false
  }

  processMultiStepForm();
});