document.addEventListener('turbolinks:load', function () {
    if ($('.permit_applications.new, .permit_applications.create, .projects.edit, .permit_applications.update, .permit_applications.edit').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on project edit form
    // it should only serve the permit applications form
    if ($('.permit_applications-form-section').length === 0) {
        return false
    }

    if ($('.projects.edit, .permit_applications.update, .permit_applications.edit').length !== 0) {
        processSelectionChanges();
    }

    function processSelectionChanges() {
        let constructionValue = $("#constructionSelectionTab input[type='radio']:checked").val();
        let projectValue = $("#projectTypeSelectionTab input[type='radio']:checked").val();
        if (constructionValue?.length > 0) {
            constructionSelection(constructionValue)
        }
        if (projectValue?.length > 0) {
            projectSelection(projectValue)
        }
        toggleTypeOfWork()
    }

    $(document).on('change', "#constructionSelectionTab input[type='radio']", function () {
        let value = $(this).val()
        constructionSelection(value)
        toggleTypeOfWork()
    })

    $(document).on('change', "#projectTypeSelectionTab input[type='radio']", function () {
        let value = $(this).val()
        projectSelection(value)
        toggleTypeOfWork()
    })

    function constructionSelection(value) {
        if (value === 'Residential- Single Family') {
            selectService('residential')
            deSelectService('commercial')
        } else {
            selectService('commercial')
            deSelectService('residential')
        }
    }

    function projectSelection(value) {
        if (value === 'New Construction') {
            selectService('new_construction')
            deSelectService('renovation')
        } else {
            selectService('renovation')
            deSelectService('new_construction')
        }
    }

    function selectService(service) {
        $("#" + service).addClass("selected");
    }

    function deSelectService(service) {
        $("#" + service).removeClass("selected");
    }

    function toggleTypeOfWork() {
        let constructionSelectionValue = $("#constructionSelectionTab input:radio:checked").val()
        let projectTypeSelectionValue = $("#projectTypeSelectionTab input:radio:checked").val()
        let typeOfWork = $('.type-of-work')
        if (constructionSelectionValue === 'Residential- Single Family' && projectTypeSelectionValue === 'Addition/ Renovation') {
            if (typeOfWork.hasClass('d-none')) {
                typeOfWork.removeClass('d-none')
            }
        } else {
            if (!typeOfWork.hasClass('d-none')) {
                typeOfWork.addClass('d-none')
            }
        }
        typeOfWorkChanges()
    }

    function typeOfWorkChanges() {
        $(document).off('change', '.type-of-work input:checkbox').on('change', '.type-of-work input:checkbox', function () {
            var checkbox = $(this)
            var checkboxValue = checkbox.val();
            if (checkbox.is(':checked')) {
                handlePermitInformationCheck(checkboxValue, true)
            } else {
                handlePermitInformationCheck(checkboxValue, false)
            }
        })
    }

    function handlePermitInformationCheck(checkboxValue, select_permit) {
        ['Solar', 'Swimming Pool'].forEach((data) => {
            if (checkboxValue === data) {
                document.querySelectorAll('.permit-checkbox').forEach((div) => {
                    let permitCheckbox = div.querySelector("input[type='checkbox']")
                    let value = permitCheckbox.value
                    if(value === data) {
                        permitCheckbox.checked = select_permit
                    }
                })
            }
        })
    }
});