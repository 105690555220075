document.addEventListener('turbolinks:load', function () {
    if ($('.accounts.setting, .project_folders.show, .organizations.edit, .teams.edit').length === 0) {
        return false
    }

    $('.upload-btn').on('click', function (e) {
        e.preventDefault();
        $(this).parent().find('.file-field').click();
    })

    $('.file-field').on('change', function () {
        $(this).closest("form").submit();
    })

});