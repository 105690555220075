document.addEventListener('turbolinks:load', function () {
    if ($('.contractor-portal.onboarding.new, .contractor-portal.onboarding.create, .contractor-portal.settings.index').length === 0) {
        return false
    }

    // for previewing picture
    var uploadBtn = document.querySelector('.upload-image-btn');
    if (uploadBtn) {
        var fileObject = document.querySelector('#user_avatar');
        uploadBtn.addEventListener('click', function (e) {
            e.preventDefault();
            fileObject.click();
        });

        fileObject.addEventListener('change', function () {
            var reader = new FileReader();

            reader.addEventListener("load", function () {
                var displayAvatar = $('.display-avatar')
                var defaultDp = $('.default-dp')
                if (displayAvatar) {
                    displayAvatar.attr('src', reader.result)
                    displayAvatar.removeClass('d-none')
                    defaultDp.addClass('d-none')
                }
            }, false);
            if (this.files[0]) {
                reader.readAsDataURL(this.files[0]);
            }
        });
    }

    // for showing contractor role section
    var contractorRole = $('.contractor-role-section')

    $(document).off('change', '.role-radio').on('change', '.role-radio', function () {
        triggerRole($(this).val())
    })

    triggerRole($('.role-section input:radio:checked').val())

    function triggerRole(val) {
        if (val === 'Contractor') {
            contractorRole.removeClass('d-none')
            toggleRequiredField(true)
        } else {
            contractorRole.addClass('d-none')
            toggleRequiredField(false)
        }
    }

    function toggleRequiredField(required) {
        if (required) {
            contractorRole.find('.is_required_input').each(function () {
                var id = $(this).attr('id')
                if (['user_contractor_licenses', 'user_contractor_insurance_documents'].includes(id)) {
                    var filePresent = $(this).attr('data-files-present') === "true"
                    if (!filePresent) {
                        $(this).addClass('requiredField')
                    }
                } else {
                    $(this).addClass('requiredField')
                }
            })
        } else {
            contractorRole.find('.is_required_input').each(function () {
                $(this).removeClass('requiredField')
            })
        }
    }
});